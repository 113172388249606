<template>
  <div>
    <validation-observer ref="observer">
      <form @submit.prevent="save">
        <div class="task-container pa-9 pa-md-6 pa-lg-9 mx-10">
          <v-container>
            <v-row align="center">
              <v-col cols="12" class="mb-6">
                <h3 class="page-title font-weight-bold">従業員の編集</h3>
                <span>従業員の編集を行います。</span>
              </v-col>

              <template v-if="loading">
                <v-col cols="12">
                  <div class="form-content">
                    <v-container>
                      <v-skeleton-loader
                        class="mb-6"
                        type="card-avatar, article, actions"
                      ></v-skeleton-loader>
                    </v-container>
                  </div>
                </v-col>
              </template>
              <template v-else>
                <v-col cols="3" class="mb-2" offset-md="3">
                  <img :src="preview_file" class="image_preview mb-2" />
                  <div class="d-flex">
                    <v-btn text class="red--text" @click="removeFile()"
                      >ｰ 削除</v-btn
                    >
                    <v-btn text class="blue--text pointer" @click="openFile()"
                      >+ アップロード</v-btn
                    >
                  </div>
                  <input
                    type="file"
                    style="display: none"
                    ref="uploader"
                    accept="image/*"
                    @change="onFilePicked"
                  />
                </v-col>

                <v-col cols="12" id="dateTimePart">
                  <div class="form-content">
                    <v-row align="center">
                      <v-col cols="3" class="form-header text-heading-3 text-right">
                        表示順
                      </v-col>
                      <v-col cols="4" class="flex-grow-1">
                        <validation-provider v-slot="{ errors }" name="display_order" rules="between:1,99">
                        <v-text-field
                          v-model="data.display_order"
                          :error-messages="errors"
                          :error="errors.length !== 0"
                          class="form-text"
                          dense
                          hide-details="auto"
                          outlined
                          placeholder="1~99の数字を一つ入力してください。"
                        ></v-text-field>
                        </validation-provider>
                      </v-col>
                    </v-row>
                    <v-row align="center">
                      <v-col cols="3" class="form-header text-heading-3 text-right">
                        お名前
                      </v-col>
                      <v-col cols="2" class="flex-grow-1">
                        <validation-provider v-slot="{ errors }" name="last_name" rules="required">
                          <v-text-field
                            v-model="data.last_name"
                            :error-messages="errors"
                            :error="errors.length !== 0"
                            class="form-text"
                            dense
                            hide-details="auto"
                            outlined
                            placeholder="姓"
                          ></v-text-field>
                        </validation-provider>
                      </v-col>
                      <v-col cols="2" class="flex-grow-1">
                        <validation-provider v-slot="{ errors }" name="first_name" rules="required">
                          <v-text-field
                            v-model="data.first_name"
                            class="form-text"
                            :error-messages="errors"
                            :error="errors.length !== 0"
                            dense
                            hide-details="auto"
                            outlined
                            placeholder="名"
                          ></v-text-field>
                        </validation-provider>
                      </v-col>
                    </v-row>

                    <v-row align="center">
                      <v-col cols="3" class="form-header text-heading-3 text-right">
                        フリガナ
                      </v-col>
                      <v-col cols="2" class="flex-grow-1">
                        <v-text-field
                          v-model="data.furigana_last_name"
                          class="form-text"
                          dense
                          hide-details
                          outlined
                          placeholder="セイ"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="2" class="flex-grow-1">
                        <v-text-field
                          v-model="data.furigana_first_name"
                          class="form-text"
                          dense
                          hide-details
                          outlined
                          placeholder="メイ"
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row align="center">
                      <v-col cols="3" class="form-header text-heading-3 text-right">
                        郵便番号
                      </v-col>
                      <v-col cols="4" class="flex-grow-1">
                        <v-text-field
                          v-model="data.postcode"
                          @input="changePostal"
                          class="form-text"
                          dense
                          hide-details
                          outlined
                          placeholder="0000000"
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row align="center">
                      <v-col cols="3" class="form-header text-heading-3 text-right">
                        都道府県
                      </v-col>
                      <v-col cols="4" class="flex-grow-1">
                        <v-select
                          v-model="data.prefecture_id"
                          :items="prefectures"
                          item-text="name"
                          item-value="id"
                          class="form-text"
                          dense
                          hide-details
                          outlined
                          placeholder="都道府県を選択してください。"
                        ></v-select>
                      </v-col>
                    </v-row>

                    <v-row align="center">
                      <v-col cols="3" class="form-header text-heading-3 text-right">
                        市区町村
                      </v-col>
                      <v-col cols="4" class="flex-grow-1">
                        <v-text-field
                          v-model="data.city"
                          class="form-text"
                          dense
                          hide-details
                          outlined
                          placeholder="市区町村を入力してください。"
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row align="center">
                      <v-col cols="3" class="form-header text-heading-3 text-right">
                        番地・建物名・号室
                      </v-col>
                      <v-col cols="7" class="flex-grow-1">
                        <v-text-field
                          v-model="data.address"
                          class="form-text"
                          dense
                          hide-details
                          outlined
                          placeholder="番地・建物名・号室 を入力してください。"
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row align="center">
                      <v-col cols="3" class="form-header text-heading-3 text-right">
                        連絡先
                      </v-col>
                      <v-col cols="7" class="flex-grow-1">
                        <v-text-field
                          v-model="data.phone"
                          class="form-text"
                          dense
                          hide-details
                          outlined
                          placeholder="00000000000"
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row align="center">
                      <v-col cols="3" class="form-header text-heading-3 text-right">
                        メールアドレス
                      </v-col>
                      <v-col cols="7" class="flex-grow-1">
                        <validation-provider v-slot="{ errors }" name="email" rules="required">
                          <v-text-field
                            v-model="data.email"
                            class="form-text"
                            :error-messages="errors"
                            :error="errors.length !== 0"
                            dense
                            hide-details="auto"
                            outlined
                            placeholder="abc@gmail.com"
                          ></v-text-field>
                        </validation-provider>
                      </v-col>
                    </v-row>

                    <v-row align="center">
                      <v-col cols="3" class="form-header text-heading-3 text-right">
                        緊急連絡先
                      </v-col>
                      <v-col cols="7" class="flex-grow-1">
                        <v-text-field
                          v-model="data.emergency_contact"
                          class="form-text"
                          dense
                          hide-details
                          outlined
                          placeholder="0000000"
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row align="center">
                      <v-col cols="3" class="form-header text-heading-3 text-right">
                        生年月日
                      </v-col>
                      <v-col cols="4" class="flex-grow-1">
                        <v-dialog
                          ref="dialogBirthDay"
                          v-model="modal.birth_day"
                          :return-value.sync="data.birth_day"
                          width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="data.birth_day"
                              readonly
                              class="form-text"
                              v-bind="attrs"
                              v-on="on"
                              dense
                              hide-details
                              outlined
                              placeholder="日付選択"
                            ></v-text-field>
                          </template>
                          <v-date-picker v-model="data.birth_day" scrollable :day-format="formatDate">
                            <v-spacer></v-spacer>
                            <v-btn
                              text
                              color="primary"
                              @click="modal.birth_day = false"
                            >
                              キャンセル
                            </v-btn>
                            <v-btn
                              text
                              color="primary"
                              @click="$refs.dialogBirthDay.save(data.birth_day)"
                            >
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-dialog>
                      </v-col>
                    </v-row>

                    <v-row align="center">
                      <v-col cols="3" class="form-header text-heading-3 text-right">
                        血液型
                      </v-col>
                      <v-col cols="7" class="flex-grow-1">
                        <v-radio-group
                          class="radio-group mt-0"
                          v-model="data.blood_type"
                          row
                          hide-details
                        >
                          <v-radio
                            v-for="(bloodType, bloodTypeIndex) in blood_types"
                            :value="bloodType.value"
                            :key="`radio-${bloodTypeIndex}`"
                            bloodType="#ACACAC"
                          >
                            <template v-slot:label>
                              <div class="location-bloodType">
                                {{ bloodType.text }}
                              </div>
                            </template>
                          </v-radio>
                           <span class="unselect" @click="data.blood_type = null">
                  選択解除
                  </span>
                        </v-radio-group>
                      </v-col>
                    </v-row>

                    <v-row align="center">
                      <v-col cols="3" class="form-header text-heading-3 text-right">
                        入社日
                      </v-col>
                      <v-col cols="4" class="flex-grow-1">
                        <v-dialog
                          ref="dialogDateOfJoining"
                          v-model="modal.date_of_joining"
                          :return-value.sync="data.date_of_joining"
                          width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="data.date_of_joining"
                              readonly
                              class="form-text"
                              v-bind="attrs"
                              v-on="on"
                              dense
                              hide-details
                              outlined
                              placeholder="日付選択"
                            ></v-text-field>
                          </template>
                          <v-date-picker v-model="data.date_of_joining" scrollable :day-format="formatDate">
                            <v-spacer></v-spacer>
                            <v-btn
                              text
                              color="primary"
                              @click="modal.date_of_joining = false"
                            >
                              キャンセル
                            </v-btn>
                            <v-btn
                              text
                              color="primary"
                              @click="
                                $refs.dialogDateOfJoining.save(data.date_of_joining)
                              "
                            >
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-dialog>
                      </v-col>
                    </v-row>

                    <v-row align="center">
                      <v-col cols="3" class="form-header text-heading-3 text-right">
                        雇用形態
                      </v-col>
                      <v-col cols="4" class="flex-grow-1">
                        <v-select
                          v-model="data.contract_type"
                          :items="contact_types"
                          class="form-text"
                          dense
                          hide-details
                          outlined
                          placeholder="ご選択ください。"
                        ></v-select>
                      </v-col>
                    </v-row>

                    <v-row align="center">
                      <v-col cols="3" class="form-header text-heading-3 text-right">
                        従業員タイプ
                      </v-col>
                      <v-col cols="4" class="flex-grow-1">
                        <validation-provider v-slot="{ errors }" name="employee_type" rules="required">
                          <v-select
                            v-model="data.employee_type"
                            :items="employee_types"
                            :error-messages="errors"
                            :error="errors.length !== 0"
                            class="form-text"
                            dense
                            hide-details="auto"
                            outlined
                            placeholder="ご選択ください。"
                          ></v-select>
                        </validation-provider>
                      </v-col>
                    </v-row>

                    <v-row align="center">
                      <v-col cols="3" class="form-header text-heading-3 text-right">
                        管理権限
                      </v-col>
                      <v-col cols="4" class="flex-grow-1">
                        <validation-provider v-slot="{ errors }" name="role" rules="required">
                          <v-select
                            v-model="data.role"
                            :items="roles"
                            :error-messages="errors"
                            :error="errors.length !== 0"
                            class="form-text"
                            dense
                            hide-details="auto"
                            outlined
                            placeholder="ご選択ください。"
                          ></v-select>
                        </validation-provider>
                      </v-col>
                    </v-row>

                    <v-row align="center">
                      <v-col cols="3" class="form-header text-heading-3 text-right">
                        ステータス
                      </v-col>
                      <v-col cols="7" class="flex-grow-1">
                        <v-radio-group
                          class="radio-group mt-0"
                          v-model="data.status"
                          row
                          hide-details
                        >
                          <v-radio
                            v-for="(status, statusIndex) in statuses"
                            :value="status.value"
                            :key="`radio-${statusIndex}`"
                            status="#ACACAC"
                          >
                            <template v-slot:label>
                              <div class="location-status">
                                {{ status.text }}
                              </div>
                            </template>
                          </v-radio>
                        </v-radio-group>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="3" class="form-header text-heading-3 text-right">
                        メモ
                      </v-col>
                      <v-col cols="9" class="flex-grow-1">
                        <v-textarea
                          v-model="data.memo"
                          class="form-text"
                          hide-details
                          outlined
                          placeholder="従業員に関するメモを入力してください。"
                          rows="7"
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </div>
                </v-col>
              </template>
            </v-row>
          </v-container>
        </div>
        <div class="create-task-footer px-6">
          <v-row>
            <v-col cols="auto" class="flex-grow-1">
              <v-btn
                text
                color="#AD4545"
                rounded
                min-width="150"
                @click="deleteItem()"
                :loading="loading"
                :disabled="disableForm"
              >
                <v-icon left>
                  $trash
                </v-icon>
                削除する
              </v-btn>
            </v-col>

            <v-col cols="auto">
              <v-btn
                @click="$router.push('/management/employee')"
                text
                rounded
                min-width="150"
                class="mr-8"
                >キャンセル</v-btn
              >
            </v-col>

            <v-col cols="auto">
              <v-btn @click="save" color="#4F55A7" class="white--text"
                >従業員情報を更新する</v-btn
              >
            </v-col>
          </v-row>
        </div>
      </form>
    </validation-observer>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import dayjs from "dayjs";
export default {
  name: 'Create',
  components: {},
  props: ['id'],
  data() {
    return {
      loading: true,
      preview: null,
      blood_types: [
        { value: 1, text: 'A' },
        { value: 2, text: 'B' },
        { value: 3, text: 'AB' },
        { value: 4, text: 'O' }
      ],
      contact_types: [
        { value: 0, text: '短時間労働者' },
        { value: 1, text: '正社員' },
        { value: 2, text: '契約社員' },
        { value: 3, text: '協業' },
        { value: 4, text: '他' }
      ],
      employee_types: [
        { value: 0, text: '通常業務' },
        { value: 1, text: '日常清掃員' },
        { value: 2, text: '協業会社' }
      ],
      roles: [
        { value: 'A', text: 'アドミン' },
        { value: 'E', text: '従業員' }
      ],
      statuses: [
        { value: 1, text: 'アクティブ' },
        { value: 0, text: '非アクティブ' }
      ],
      modal: {
        birth_day: false,
        date_of_joining: false,
        delete: false
      },
      alert: { container: false, message: '' },
      file: null
    }
  },
  computed: {
    disableForm() {
      return this.loading
    },
    prefectures() {
      return this.$store.getters.allPrefectures
    },
    data() {
      return this.$store.getters.userDetail
    },
    preview_file: {
      get() {
        if (this.preview) return this.preview

        return `${ this.data.profile_image ? this.data.profile_image_url : process.env.VUE_APP_BASEURL + 'images/userblank-rectangle.png'}`
      },
      set(value) {
        this.preview = value
      }
    }
  },
  created() {
    this.$store.dispatch('PREFECTURE_GET_ALL')
    this.$store.dispatch('USER_GET', this.id).then(() => {
      this.loading = false
    })
  },
  methods: {
    ...mapMutations(['showModal']),
    changePostal() {
      var postal_code = require('japan-postal-code')
      this.resetPrefectureCity()

      postal_code.get(this.data.postcode, address => {
        let selected_prefecture = this.prefectures.filter(
          prefecture => address.prefecture === prefecture.name
        )[0]

        this.data.prefecture = selected_prefecture.name
        this.data.prefecture_id = selected_prefecture.id
        this.data.city = address.city
      })
    },
    resetPrefectureCity() {
      this.data.prefecture = null
      this.data.prefecture_id = null
      this.data.city = null
    },
    save() {
      this.$refs.observer.validate().then(success => {
        if (!success) {
          return
        }
        let id = this.data.id
        let data = new FormData()
        data.append('_method', 'PUT')
        data.append('profile_image', this.file)
        for (const [key, value] of Object.entries(this.data)) {
          data.append(key, value ? value : value == 0 ? 0 : '')
        }

        this.$store
          .dispatch(
            'USER_UPDATE',
            { id, data },
            {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }
          )
          .then(
            response => {
              if (response.data.status == 'success') {

                // window.location.href = '/management/employee'
                this.$router.push({
                  path: '/management/employee',
                  query: { status: 'success', message: '従業員情報を更新しました。' }
                })
              }
            },
            error => {
              this.$swal.fire({
                title: 'システムエラー',
                text: '登録時に不具合が生じています。システム管理者へ問い合わせてください。',
                icon: 'error',
                confirmButtonText: '閉じる'
              })
              throw error
            }
          )
      })
    },
    deleteItem() {
      this.showModal({
        text: 'このアクションを実施してよろしいですか',
        action: {
          process: {
            color: 'red',
            text: '実施する'
          },
          cancel: {
            color: 'secondary',
            text: 'キャンセル'
          }
        },
        func: this.processDelete
      })
    },

    async processDelete() {
      this.loading = true
      await this.$store
        .dispatch('USER_DELETE', { id: this.$route.params.id })
        .then(response => {
          if (response.status === 200) {
            this.$router.push({
              name: 'EmployeeManagement'
            })
          }
        })
      this.loading = false
    },
    openFile() {
      this.$refs.uploader.click()
    },
    onFilePicked(e) {
      this.file = e.target.files[0]
      this.preview_file = URL.createObjectURL(this.file)
    },
    removeFile() {
      this.file = null
      this.preview_file = null
      this.data.profile_image = null
      this.data.profile_image_deleted = true
    },
    formatDate(e) {
      return dayjs(e).format('D')
    }
  }
}
</script>

<style src="./Edit.scss" lang="scss" scoped></style>
